import React, { useState, useContext } from 'react';

const initialSiteState = {
  navOpen: false,
  homeHeader: false,
  accessGuides:false,
};

const SiteContext = React.createContext({
  nav: initialSiteState,
  setNav: () => null,
});

const SiteContextProvider = ({ children }) => {
  const [nav, setNav] = useState(initialSiteState);  

  return (
    <SiteContext.Provider
      value={{
        nav,
        setNav,
      }}
    >
      {children}
    </SiteContext.Provider>
  );
};


function useSiteState() {
  const {
    nav: { navOpen, homeHeader, accessGuides },
    setNav,   
  } = useContext(SiteContext);

  function setHomeHeader(homeHeaderUpdate) {
    setNav((prevState) => {
      return {
        ...prevState,
        homeHeader: homeHeaderUpdate,
      };
    });
  }

  function setAccessGuides(accessGuidesUpdate) {
    setNav((prevState) => {
      return {
        ...prevState,
        accessGuides: accessGuidesUpdate,
      };
    });
  }

  async function toggleNav() {
    setNav((prevState) => {
      return {
        ...prevState,
        navOpen: !navOpen,       
      };
    });
  }
  async function closeNav() {
    setNav((prevState) => {
      return {
        ...prevState,
        navOpen: false,        
      };
    });
  }   


  return {
    navOpen,
    toggleNav,
    closeNav,
    homeHeader,
    setHomeHeader,
    accessGuides,
    setAccessGuides,
  };
}

export { useSiteState, SiteContextProvider };
